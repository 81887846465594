// Shared styles
.item-list-container {
	display: grid;
	grid-gap: 5px;
	background: $grey;
	grid-template-columns: repeat(auto-fit, 48px);
	grid-template-rows: repeat(auto-fit, 48px);
	width: 100%;
	&.is-draggable-disabled .item {
		cursor: pointer;
		&.is-read-only {
			cursor: auto;
		}
	}
	padding: 10px;
	img {
		width: 100%;
	}
}
.item {
	position: relative;
	&:not([data-qty])::after,
	&[data-qty='0']::after {
		display: none;
	}
	&::after {
		content: attr(data-qty);
		position: absolute;
		color: $black;
		font-size: 16px;
		bottom: 0;
		right: 0;
		background: #eadba2;
		padding: 3px;
		line-height: 0.7;
		font-weight: bold;
		outline: 1px solid $white-bis;
		border: 1px solid $black;
		text-shadow: 1px 1px 0 #fff;
	}
	&.is-not-acquired::after {
		filter: saturate(0);
		color: #666
	}
	&.is-selected::before {
		content: ' ';
		border: 2px solid $zeldaBrown;
		position: absolute;
		width: 100%;
		height: 100%;
	}
	&.item-select {
		border: 3px dashed $info;
		display: flex;
		align-items: center;
		.item-select-plus {
			width: 100%;
			font-size: 25px;
			text-align: center;
		}
	}
}
.item-container {
	width: 48px;
	height: 48px;
	position: relative;
	margin: 4.5px;
}
.item.is-item-group {
	position: absolute;
}
.details-container {
	.item-select-container {
		.item.is-read-only {
			cursor: pointer;
		}
		.item.is-item-group {
			position: static;
		}
	}
	.details-area-collectables .item-container {
		margin: 0 4.5px;
	}
}
.item-list-group-container {
	display: flex;
	flex-direction: column;
}
.item-list-group + .item-list-group {
	border-top: 1px solid #ccc;
	border-left: none;
	margin: 30px 0 0;
	padding: 30px 0 0;

}
.item-exchange-divider {
	width: 100%;
	font-size: 40px;
	margin: 20px 0;
	transform: rotate(90deg);
}
// Dungeon List
.item-list-container.dungeon-list-container {
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
	.dungeon-container {
		padding: 5px;
	}
	.dungeon-title {
		//text-transform: uppercase;
	}
	.dungeon-grid-container {
		display: grid;
		margin: 10px 0 0;
		//grid-template-columns: repeat(4, 32px);
		//grid-template-rows: repeat(3, 32px);
		grid-template-columns: 32px 32px 10px 32px;
		grid-template-rows: 32px 32px;
		//grid-gap: 10px;
		grid-template-areas:
			"boss boss . prize"
			"boss boss . medallion"
		;
		.boss {
			width: 64px;
			height: 64px;
			grid-area: boss;
		}
		.prize {
			width: 32px;
			height: 32px;
			grid-area: prize;
			position: relative;
		}
		.chests {
			width: 32px;
			height: 32px;
			grid-area: chests;
		}
		.medallion {
			width: 32px;
			height: 32px;
			position: relative;
			grid-area: medallion;
		}
	}
}
// Modal Styles
.modal {
	.item-list-group-container {
		padding: 10px;
		border: 1px solid #ccc;
		& + .item-list-group-container {
			margin: 20px 0 0;
		}
	}
	.item-list-group-subtitle {
		font-weight: 300;
	}
	.item-list-container {
		position: static;
		overflow: auto;
		flex-wrap: nowrap;
		display: flex;
		height: 125px;
	}
	.item-select-modal .item-list-container {
		flex-wrap: wrap;
		height: auto;
	}
	.edit-item-list-controls {
		margin: 0 0 20px;
		padding: 0 0 20px;
		border-bottom: 1px solid #ccc;
	}
	.item-list-group {

	}
}
