//@import url('https://fonts.googleapis.com/css?family=Roboto');
//@import '../../node_modules/bulmaswatch/slate/variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700');
// Variable overrides
$zeldaBlue: #4068AE;
$zeldaSlateBlue: #3F6684;
$zeldaTan: #DCDDD6;
$zeldaGreen: #3B5D37;
$zeldaOlive: #ffc800;
$zeldaBrown: #caa975;
$favorite: #ff9ae9;
$navbar-background-color: $zeldaBrown;
$navbar-z: 9999;
$navbar-fixed-z: 9999;
$navbar-item-color: #34221b;
$navbar-item-hover-color: $zeldaTan;
$navbar-item-hover-background-color: $zeldaSlateBlue;
$navbar-dropdown-arrow: #fff;
$family-sans-serif: 'Roboto', sans-serif;
$primary: $zeldaBlue;
$info: #0cb0de;
$success: #72b435;
$warning: #fea041;
//$dark: $zeldaSlateBlue;
$danger: #e14633;
$light: $zeldaTan;
$box-radius: 0;
@import '../../node_modules/bulma/bulma';
//@import '../../node_modules/bulmaswatch/slate/overrides';

body {
	background: $light;
}