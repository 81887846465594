@import 'fontawesome-config.scss';
@import 'bulma.config';
@import 'ItemList';
@import 'Layout';
@import 'LocationDetail';
@import 'marker-cluster';

html, body {
	background: $grey-light;
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
#root {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	display: flex;
	overflow: hidden;
}
.main-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.layout-container {
	//border-top: 7px solid #000;
	position: relative;
	flex-grow: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.flexlayout__tab[style*='z-index'] {
	// Bug fix for when map panel is maximized. It still shows both maps.
	z-index: 401 !important;
}
button {
	cursor: pointer;
}
.message-container {
	position: absolute;
	z-index: 25;
}
.is-off-screen {
	position: absolute;
	z-index: -9999 !important;
}
.main-menu-button {
	height: 100%;
	border-radius: 0;
}
.navbar {
	min-height: 56px;
	&.is-menu-closed {
		width: 0;
		.is-hideable-navbar-item {
			display: none;
		}
	}
}
.navbar-menu {
	display: flex;
}
.navbar-start {
	margin-left: 20px;
}
.navbar-menu.is-active {
	position: absolute;
	width: 100%;
}
.navbar-brand-title {
	line-height: 1;
	margin: 0;
	padding: 0;

}
.is-not-acquired img {
	opacity: 0.50;
	filter: saturate(0);
}
.navbar-item .button { justify-content: left; }
.awesome-marker i {
	margin: -11px 0 0;
}
.modal {
	z-index: 2000;
}
.item-icon {
	display: flex;
	align-items: center;
	+ * {
		margin: 0 0 0 10px;
	}
	img {
		margin: 0 10px 0 0;
		width: 32px;
	}
}
.details-notes .item-icon {
	display: inline-flex;
	img {
		margin: 0 5px;
		width: 16px;
	}
}
.item-icon-name {
	margin: 0 10px;
}
.item-icon-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, 32px 95px);
	align-items: center;
	grid-gap: 20px 0;
}
.map-container {
	width: 100%;
	height: 100%;
}
.map-body {
	height: calc(100% - 70px);
	overflow: hidden;
}
.size-controls {
	display: flex;
	margin: 0 0 0 10px;
	padding: 0;
	font-weight: 300;
	> div {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0 0 0 25px;
	}
	label {
		margin: 0 5px 0 0;
	}
}
.map {
	height: 100%;
}

.edit-item-list-modal {
	width: 90vw;
	height: 85vh;
}
.leaflet-container {
	$stripe1: rgba(235,235,235,0.65);
	$stripe2: rgba(220,220,220,0.65);
	background-image: linear-gradient(45deg, $stripe1 25%, $stripe2 25%, $stripe2 50%, $stripe1 50%, $stripe1 75%, $stripe2 75%, $stripe2 100%);
	background-size: 56.57px 56.57px;
}
.splitter-size {
	display: flex;
	align-items: center;
	p {
		margin: 0 0 0 10px;
	}
}
.awesome-marker i {
	font-size: 20px;
	// Skull with circle dot below it, indicating boss is still alive with possible progression
	&.is-dungeon-possible::after {
		content: '\f192';
		position: absolute;
		bottom: 9px;
		left: 14px;
		font-size: 6px;
	}
}
.custom-marker-cluster {
	margin-left: -20px;
	margin-top: -20px;
	width: 40px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.6);
	&.are-unavailable {
		background-color: rgba(224, 70, 51, 0.6);
	}
	&.are-partially-available {
		background-color: rgba(254, 160, 65, 0.6);
	}
	&.are-available {
		background-color: rgba(114, 180, 53, 0.6);
	}
}
.map-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 40px 0 0;
	border: 3px solid #fff;
}
.map-toolbar {
	position: absolute;
	z-index: 400;
	top: 0;
	left: 0;
	background: #fff;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	.field {
		margin: 3px 5px;
	}
}
.config-modal {
	.field + .field {
		border-top: 1px solid #ccc;
		padding: 10px 0 0;
		margin: 10px 0 0;
	}
}
fieldset {
	border: 1px solid #ccc;
	padding: 10px;
}
legend {
	font-weight: bold;
}
label {
	cursor: pointer;
}
.panel-container.panel-gray {
	width: 100%;
	height: 100%;
	background: #7a7a7a;
}
.legend-marker {
	position: relative;
	width: 35px;
	height: 46px;
}