.flexlayout__layout {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	overflow: hidden;
}

.flexlayout__splitter {
	background-color: $grey-light;
	&::after {
		@extend .fas;
		@extend .fa-grip-horizontal;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-size: 15px;
		content: '\f58d\f58d\f58d';
		word-break: break-word;
		position: absolute;
		text-shadow: 1px 1px 0 #fff;
		color: rgba(0,0,0,.5);
		font-weight: bold;
		top: -3px;
		left: calc(50% - 22.5px);
	}
	// I know. Don't ask. This is what happens when you're backed into a corner.
	&[style*='height: 13px']::after {
		top: -2px;
	}
	&[style*='height: 15px']::after {
		top: -1px;
	}
	&[style*='height: 18px']::after {
		top: 0;
	}
	&[style*='height: 20px']::after {
		top: 1px;
	}
	&[style*='ew-resize'] {
		&::after {
			@extend .fa-grip-vertical;
			content: '\f58e\f58e\f58e';
			top: calc(50% - 22.5px);
			left: 0;
			word-break: break-word;
		}
		&[style*='width: 13px']::after {
			left: 1px;
		}
		&[style*='width: 15px']::after {
			left: 2px;
		}
		&[style*='width: 18px']::after {
			left: 3px;
		}
		&[style*='width: 20px']::after {
			left: 4px;
		}
	}
}

.flexlayout__splitter:hover {
	background-color: $zeldaOlive;
	box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.flexlayout__splitter_drag {
	border-radius: 5px;
	background-color: #444;
	z-index: 1000;
}

.flexlayout__outline_rect {
	position: absolute;
	cursor: move;
	border: 2px solid #cfe8ff;
	box-shadow: inset 0 0 60px rgba(0, 0, 0, .2);
	border-radius: 5px;
	z-index: 1000;
	box-sizing: border-box;
}

.flexlayout__outline_rect_edge {
	cursor: move;
	border: 2px solid #b7d1b5;
	box-shadow: inset 0 0 60px rgba(0, 0, 0, .2);
	border-radius: 5px;
	z-index: 1000;
	box-sizing: border-box;
}

.flexlayout__edge_rect {
	position: absolute;
	z-index: 1000;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
	background-color: lightgray;
}

.flexlayout__drag_rect {
	position: absolute;
	cursor: move;
	border: 2px solid #aaaaaa;
	box-shadow: inset 0 0 60px rgba(0, 0, 0, .3);
	border-radius: 5px;
	z-index: 1000;
	box-sizing: border-box;
	background-color: #eeeeee;
	opacity: 0.9;
	text-align: center;

	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	padding: 10px;
	word-wrap: break-word;
}

.flexlayout__tabset {
	overflow: hidden;
	background-color: $white;
	box-sizing: border-box;
}

.flexlayout__tab {
	overflow: auto;
	position: absolute;
	box-sizing: border-box;
	background-color: $white;
	border-top: 5px solid $white;
}

.flexlayout__tab_button {
	cursor: pointer;
	padding: 2px 8px 3px 8px;
	margin: 8px 2px 0 4px;
	float: left;
	vertical-align: top;
	box-sizing: border-box;
}

.flexlayout__tab_button--selected {
	color: $black-ter;
	background: $white;
}

.flexlayout__tab_button--unselected {
	color: $white;
	background: none;
	font-weight: 300;
}

.flexlayout__tab_button_leading {
	float: left;
	display: inline-block;
	max-width: 15px;
}

.flexlayout__tab_button_content {
	float: left;
	display: inline-block;
}

.flexlayout__tab_button_textbox {
	float: left;
	color: $zeldaGreen;
	padding: 0 3px;
	border: 1px solid $grey;
	font-size: 15px;
	height: 25px;
}

.flexlayout__tab_button_textbox:focus {
	outline: none;
}

.flexlayout__tab_button_trailing {
	float: left;
	display: inline-block;
	margin-left: 5px;
	margin-top: 3px;
	width: 8px;
	height: 8px;
}

.flexlayout__tab_button:hover .flexlayout__tab_button_trailing,
.flexlayout__tab_button--selected .flexlayout__tab_button_trailing {
	//background: transparent url("../images/close_white.png") no-repeat center;
}

.flexlayout__tab_button_overflow {
	float: left;
	width: 20px;
	height: 15px;
	margin-top: 2px;
	padding-left: 12px;
	border: none;
	font-size: 10px;
	color: lightgray;
	font-family: Arial, sans-serif;
	//background: transparent url("../images/more.png") no-repeat left;
}

.flexlayout__tabset_header {
	position: absolute;
	left: 0;
	right: 0;
	color: #eee;
	background-color: #212121;
	padding: 3px 3px 3px 5px;
	/*box-shadow: inset 0px 0px 3px 0px rgba(136, 136, 136, 0.54);*/
	box-sizing: border-box;
}

.flexlayout__tab_header_inner {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 10000px;
}

.flexlayout__tab_header_outer {
	background-color: $zeldaSlateBlue;
	position: absolute;
	left: 0;
	right: 0;
	/*top: 0px;*/
	/*height: 100px;*/
	overflow: hidden;
}

.flexlayout__tabset-selected {
	//background: $zeldaBlue;
	//font-weight: bold;
	//background-image: linear-gradient(#111, #444);
}

.flexlayout__tabset-maximized {
	//background: $zeldaBlue;
	//background-image: linear-gradient(#666, #333);
}

.flexlayout__tab_toolbar {
	position: absolute;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	top: 5px;
	bottom: 0;
	right: 0;
	@extend .icon;
}

.flexlayout__tab_toolbar_button-min {
	color: $white;
	height: 35px;
	border: none;
	margin: 0 10px 0 0;
	background: none;
	outline-width: 0;
	@extend .fas;
	@extend .fa-window-maximize;
	//background: transparent url("../images/maximize.png") no-repeat center;

}

.flexlayout__tab_toolbar_button-max {
	color: $white;
	height: 35px;
	border: none;
	margin: 0 10px 0 0;
	outline-width: 0;
	background: none;
	@extend .fas;
	@extend .fa-window-minimize;
	//background: transparent url("../images/restore.png") no-repeat center;
}

.flexlayout__popup_menu {
}

.flexlayout__popup_menu_item {
	padding: 2px 10px 2px 10px;
	color: #ddd;
}

.flexlayout__popup_menu_item:hover {
	background-color: #444444;
}

.flexlayout__popup_menu_container {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, .15);
	border: 1px solid #555;
	background: #222;
	border-radius: 3px;
	position: absolute;
	z-index: 1000;
}


.flexlayout__border_top {
	background-color: #9f9f9f;
	border-bottom: 4px double #666;
	box-sizing: border-box;
	overflow: hidden;
}

.flexlayout__border_bottom {
	background-color: #9f9f9f;
	border-top: 4px double #666;
	box-sizing: border-box;
	overflow: hidden;
}

.flexlayout__border_left {
	background-color: #9f9f9f;
	border-right: 4px double #666;
	box-sizing: border-box;
	overflow: hidden;
}

.flexlayout__border_right {
	background-color: #9f9f9f;
	border-left: 4px double #666;
	box-sizing: border-box;
	overflow: hidden;
}

.flexlayout__border_inner_bottom {
	display: flex;
	padding: 1px 0 0;
}

.flexlayout__border_inner_left {
	position: absolute;
	white-space: nowrap;
	right: 34px;
	transform-origin: top right;
	transform: rotate(-90deg);
}

.flexlayout__border_inner_right {
	position: absolute;
	white-space: nowrap;
	left: 34px;
	transform-origin: top left;
	transform: rotate(90deg);
}

.flexlayout__border_button {
	background-color: $grey-lighter;
	border-radius: 10px;
	color: white;
	display: inline-block;
	white-space: nowrap;

	cursor: pointer;
	padding: 2px 8px 3px 8px;
	margin: 2px 10px;
	vertical-align: top;
	box-sizing: border-box;
}
.flexlayout__border_button_top {
	margin-top: 4px;
}

.flexlayout__border_button--selected {
	color: #ddd;
	background-color: $zeldaBlue;
}

.flexlayout__border_button--unselected {
	color: gray;
}

.flexlayout__border_button_leading {
	float: left;
	display: inline;
}

.flexlayout__border_button_content {
	display: inline-block;
	padding: 0 10px;
}

.flexlayout__border_button_textbox {
	float: left;
	border: none;
	color: green;
	background-color: #ddd;
}

.flexlayout__border_button_textbox:focus {
	outline: none;
}

.flexlayout__border_button_trailing {
	display: inline-block;
	margin-left: 5px;
	margin-top: 3px;
	width: 8px;
	height: 8px;
}

.flexlayout__border_button:hover .flexlayout__border_button_trailing,
.flexlayout__border_button--selected .flexlayout__border_button_trailing {
	//background: transparent url("../images/close_white.png") no-repeat center;
}


.flexlayout__border_toolbar_left {
	position: absolute;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	bottom: 0;
	left: 0;
	right: 0;
}

.flexlayout__border_toolbar_right {
	position: absolute;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	bottom: 0;
	left: 0;
	right: 0;
}

.flexlayout__border_toolbar_top {
	position: absolute;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 0;
}

.flexlayout__border_toolbar_bottom {
	position: absolute;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 0;
}
