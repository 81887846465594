.details-container {
	display: flex;
	flex-wrap: wrap;
	> * + * {
		border-left: 1px solid #ccc;
	}
	.item {
		width: 48px;
		height: 48px;
		margin-left: 5px;
		margin-right: 5px;
		&.is-read-only {
			cursor: default;
		}
		cursor: pointer;
	}
}
.favorite-button.is-favorite {
	color: $favorite;
}
.details-location {
	min-width: 190px;
}
.details-section {
	padding: 7.5px;
}
.divider {
	border-top: 1px solid #ccc;
	box-shadow: 1px 1px 0 #fff;
	margin: 0 0 10px;
}
.details-areas {
	display: flex;
}
.details-area {
	margin: 0 7.5px;
}
.details-controls {
	display: flex;
	justify-content: space-between;
	align-items: end;
}
.details-area-collectables {
	padding: 10px 7.5px 10px;
	border: 1px solid #ccc;
	border-top: none;
	margin: -7px 0 0;
	background: #eee;
	border-radius: 0 0 5px 5px;
	display: flex;
}
.tags-container {
	display: flex;
	flex-direction: column;
}